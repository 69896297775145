<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="400"
    v-model="showVideoPlayer"
    @click:outside="$emit('closeVideoPlayer')"
  >
    <v-row justify="center" class="ma-0">
      <video-player
        class="video-player-box"
        ref="videoPlayer"
        :options="playerOptions"
        :playsinline="true"
        @ready="playerReadied"
      >
      </video-player>
    </v-row>
  </v-dialog>
</template>
<script>
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
export default {
  props: ["showVideoPlayer", "src"],
  components: {
    videoPlayer
  },
  data() {
    return {
      modal: null,
      playerReady: false,
      playerOptions: {
        // videojs options
        muted: false,
        language: "es",
        width: 320,
        height: 250,
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: this.src
          }
        ]
      }
    };
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    }
  },
  watch: {
    showVideoPlayer: function() {
      if (this.playerReady) {
        if (!this.showVideoPlayer) {
          this.player.pause();
        }
      }
    }
  },
  mounted() {
    this.modal = this.showVideoPlayer;
  },
  methods: {
    playerReadied() {
      this.playerReady = true;
      // this.$refs.videoPlayer.play();
    },
    modalAction() {
      this.$emit("close");
    }
  }
};
</script>
<style scoped>
.background {
  background-color: white;
  background-image: url("~@/assets/apotalent/background.svg");
  background-repeat: no-repeat;
  background-position: bottom;
}
.v-dialog {
  box-shadow: none !important;
}
</style>
